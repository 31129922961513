import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './Wishlist.css';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Wishlist = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.55;
    } else if(breakpoints.sm) {
      return size * 0.55;
    } else if(breakpoints.md) {
      return size * 0.85;
    } else {
      return size;
    }
  }

  return (
    process.env.GATSBY_WISHLIST_READY === 'true' ? (
      <section className="col--c--js-c section no-margin" id="wishlist">
        <h2 className="heading--l">Önskelista</h2>
        <div className="wishlist-card center">
          <Kiku16
            size={flowerSize(80)}
            fill="white" stroke="red"
            circleFill="red"
            classname="wishlist__flower--5" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="gold"
            circleFill="white"
            classname="wishlist__flower--2" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="red"
            circleFill="red"
            classname="wishlist__flower--4" />
          <KikuLayered
            size={flowerSize(160)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="wishlist__flower--1" />
          <KikuLayered
            size={flowerSize(60)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="wishlist__flower--6" />
          <Kiku18
            size={flowerSize(90)}
            fill="red" stroke="gold"
            circleFill="gold"
            classname="wishlist__flower--3" />

          <div className="col--c--js-c">
            <p className="p__end">Då vi inte har mycket plats kvar här hemma önskar vi oss inga prylar. Om man ändå vill ge en gåva uppskattar vi ett bidrag till vår kommande bröllopsresa där siktet är inställt på Japan!</p>
            <p>Belopp spelar ingen roll. Vi vill gärna att beloppet är anonymt för oss, därför kan man swisha till brudgummens mamma Karin Gelotte (070-633 77 15). Skriv "BoJ" samt vem gåvan är ifrån i meddelandet så ser Karin till att det kommer fram.</p>
          </div>
        </div>
      </section>
    ) 
    : null  
  )
}

export default Wishlist