import * as React from "react";
import Palette from '../../../utils/palette';

const Kiku18 = ({size, fill, stroke, circleFill, circleStroke, classname}) => {
  const fillColor = Palette[fill];
  const strokeColor = Palette[stroke];
  const circleFillColor = Palette[circleFill];
  const circleStrokeColor = circleStroke ? Palette[circleStroke] : Palette[stroke];
  const sizeWUnit = size + 'px';

  return (
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px" viewBox="0 0 256 256" style={{width: sizeWUnit}} className={classname}>
      <defs></defs>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M129.892,117.271l32.733-89.938c8.119-22.301-31.922-30.224-32.733-5.773V117.271L129.892,117.271z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M133.447,118.565l61.521-73.319c15.256-18.179-19.661-39.319-28.786-16.621L133.447,118.565L133.447,118.565z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M136.346,120.997l82.887-47.856c20.554-11.865-5.026-43.672-21.364-25.464L136.346,120.997L136.346,120.997z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M138.237,124.274l94.257-16.621c23.371-4.12,10.213-42.758-11.368-31.235L138.237,124.274L138.237,124.274z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M138.894,128l94.258,16.619c23.371,4.122,24.221-36.687,0.001-33.24L138.894,128L138.894,128z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M138.237,131.727l82.889,47.854c20.552,11.866,35.308-26.19,11.369-31.235L138.237,131.727L138.237,131.727z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M136.346,135.002l61.522,73.319c15.254,18.18,42.136-12.534,21.367-25.463L136.346,135.002L136.346,135.002z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M133.447,137.435l32.735,89.939c8.116,22.3,43.883,2.632,28.788-16.62L133.447,137.435L133.447,137.435z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M129.892,138.729l0.001,95.712c0,23.731,40.335,17.482,32.735-5.771L129.892,138.729L129.892,138.729z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M126.108,138.729l-32.735,89.939c-8.117,22.301,31.923,30.224,32.735,5.772L126.108,138.729L126.108,138.729z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M122.553,137.435l-61.521,73.319c-15.256,18.18,19.661,39.319,28.786,16.621L122.553,137.435L122.553,137.435z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M119.655,135.002l-82.888,47.857c-20.553,11.865,5.027,43.672,21.366,25.464L119.655,135.002L119.655,135.002z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M117.763,131.727l-94.257,16.62c-23.372,4.12-10.213,42.758,11.368,31.236L117.763,131.727L117.763,131.727z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M117.106,128l-94.257-16.62c-23.372-4.122-24.221,36.686-0.001,33.241L117.106,128L117.106,128z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M117.763,124.274L34.875,76.418c-20.553-11.866-35.308,26.19-11.37,31.235L117.763,124.274L117.763,124.274z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M119.655,120.998L58.133,47.678c-15.255-18.18-42.137,12.536-21.368,25.463L119.655,120.998L119.655,120.998z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M122.553,118.565L89.818,28.626C81.701,6.325,45.936,25.994,61.03,45.245L122.553,118.565L122.553,118.565z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M126.108,117.271V21.56c0-23.732-40.336-17.482-32.736,5.771L126.108,117.271L126.108,117.271z"/>
      <circle fill={circleFillColor} stroke={circleStrokeColor} stroke-width="4" stroke-miterlimit="10" cx="128" cy="128" r="16"/>
    </svg>
  )
}

export default Kiku18;