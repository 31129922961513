import * as React from "react";
import { Link } from "gatsby"
import './navbar.css';

import { MdClose } from "@react-icons/all-files/md/MdClose";
import NavFlowers from './NavFlowers';

const Navbar = (props) => {
  const handleClose = () => {
    props.onCloseMenu(true);
  }

  return (
    <div className={`menu ${props.showMenu && 'menu--bg-visible'}`}>
      <nav className={`menu__module col--c--js-c ${props.showMenu && 'menu__module--visible'}`}>
        <div className="menu__module-flowers--top">
          <NavFlowers />
        </div>
        <button onClick={handleClose} className={`close__button center ${props.showMenu && 'close__button--visible'}`}>
          <MdClose className="close__icon" />
        </button>
        <Link to="#" onClick={handleClose}>Hem</Link>
        <Link to="/#info" onClick={handleClose}>Info</Link>
        <Link to="/#schema" onClick={handleClose}>Den stora dagen</Link>
        <Link to="/#karta" onClick={handleClose}>Vägbeskrivning</Link>
        <Link to="/#housing" onClick={handleClose}>Övernattning</Link>
        {process.env.GATSBY_TOASTMASTER_READY === 'true' && <Link to="/#toastmaster" onClick={handleClose}>Toastmaster</Link> }
        <Link to="/#faq" onClick={handleClose}>Vanliga Frågor</Link>
        {process.env.GATSBY_WISHLIST_READY === 'true' && <Link to="/#wishlist" onClick={handleClose}>Önskelista</Link> }
        <div className="menu__module-flowers--bottom">
          <NavFlowers />
        </div>
      </nav>
    </div>
  )
}

export default Navbar