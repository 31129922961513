import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './navflowers.css';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const NavFlowers = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.95;
    } else {
      return size;
    }
  }

  return (
    <div className="nav-flowers__container">
      <Kiku16 size={flowerSize(75)} fill="red" stroke="gold" circleFill="white" classname="nav-flowers__flower--left2" />
      <Kiku16 size={flowerSize(75)} fill="red" stroke="gold" circleFill="white" classname="nav-flowers__flower--right2" />
      <Kiku18 size={flowerSize(100)} fill="white" stroke="red" circleFill="red" classname="nav-flowers__flower--left1" />
      <Kiku18 size={flowerSize(100)} fill="white" stroke="red" circleFill="red" classname="nav-flowers__flower--right1" />
      <KikuLayered
        size={flowerSize(140)}
        fill="white" stroke="gold"
        fill2="red" stroke2="gold"
        circleFill="red" circleStroke="white"
        classname="nav-flowers__flower--center" />
    </div>
  )
}

export default NavFlowers