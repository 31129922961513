import * as React from "react";
import './navbar.css';
import MenuModule from './MenuModule';

import { AiOutlineMenu } from "@react-icons/all-files/ai/AiOutlineMenu";

const Navbar = () => {
  const [showMenu, setShowMenu] = React.useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  return (
    <div className="navbar">
      <button className="navbar__button" onClick={toggleMenu}>
        <AiOutlineMenu className="navbar__icon" />
      </button>
      <MenuModule onCloseMenu={toggleMenu} showMenu={showMenu} />
    </div>
  )
}

export default Navbar