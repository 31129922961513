import * as React from "react";
import Palette from '../../../utils/palette';

const Kiku16 = ({size, fill, stroke, circleFill, circleStroke, classname}) => {
  const fillColor = Palette[fill];
  const strokeColor = Palette[stroke];
  const circleFillColor = Palette[circleFill];
  const circleStrokeColor = circleStroke ? Palette[circleStroke] : Palette[stroke];
  const sizeWUnit = size + 'px';

  return (
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg" 
      x="0px" y="0px" viewBox="0 0 256 256" style={{width: sizeWUnit}} className={classname}>
      <defs></defs>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M174.828,44.98C195.879,8.518,132-14.798,129,32.688v91.672L174.828,44.98z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M210.044,79.505c36.462-21.051-7.29-73.233-33.546-33.559l-45.834,79.388L210.044,79.505z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M223.298,127c42.103,0,30.307-67.066-12.268-45.834L131.64,127H223.298z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M211.02,174.828C247.482,195.879,270.798,132,223.311,129H131.64L211.02,174.828z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M176.492,210.044c21.053,36.462,73.235-7.29,33.563-33.546l-79.391-45.834L176.492,210.044z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M129,223.298c0,42.103,67.064,30.307,45.834-12.268L129,131.64V223.298z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M81.171,211.02C60.12,247.482,124,270.798,127,223.311V131.64L81.171,211.02z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M45.956,176.492c-36.463,21.053,7.287,73.234,33.543,33.563l45.835-79.391L45.956,176.492z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M32.7,129c-42.104,0-30.306,67.064,12.27,45.834L124.36,129H32.7z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M44.98,81.17C8.518,60.12-14.797,124,32.688,127h91.673L44.98,81.17z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M79.505,45.956C58.454,9.493,6.271,53.243,45.945,79.499l79.389,45.835L79.505,45.956z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M127,32.7c0-42.104-67.067-30.306-45.835,12.269L127,124.36V32.7z"/>
      <circle fill={circleFillColor} stroke={circleStrokeColor} stroke-width="4" stroke-miterlimit="10" cx="128" cy="128" r="13"/>   
    </svg>
  )
}

export default Kiku16;