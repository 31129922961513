import * as React from "react";
import Palette from '../../../utils/palette';

const KikuLayered = ({size, fill, stroke, fill2, stroke2, circleFill, circleStroke, classname}) => {
  const fillColor = Palette[fill];
  const strokeColor = Palette[stroke];
  const fillColor2 = Palette[fill2];
  const strokeColor2 = Palette[stroke2];
  const circleFillColor = Palette[circleFill];
  const circleStrokeColor = circleStroke ? Palette[circleStroke] : Palette[stroke];
  const sizeWUnit = size + 'px';

  return (
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px" viewBox="0 0 256 256" style={{width: sizeWUnit}} className={classname}>
      <defs></defs>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M173.692,47.037C194.222,11.477,132-11.262,129,35.048v89.403L173.692,47.037z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M208.012,80.706c35.56-20.53-7.109-71.42-32.715-32.728L130.598,125.4L208.012,80.706z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M220.938,127c41.061,0,29.556-65.405-11.965-44.698L131.549,127H220.938z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M208.963,173.692C244.523,194.222,267.263,132,220.95,129h-89.401L208.963,173.692z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M175.292,208.012c20.53,35.56,71.422-7.109,32.73-32.715l-77.425-44.699L175.292,208.012z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M129,220.938c0,41.061,65.402,29.556,44.698-11.965L129,131.549V220.938z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M82.306,208.963C61.777,244.523,124,267.263,127,220.95v-89.401L82.306,208.963z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M47.988,175.292c-35.561,20.53,7.105,71.421,32.712,32.73l44.7-77.425L47.988,175.292z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M35.06,129c-41.061,0-29.555,65.402,11.966,44.698L124.451,129H35.06z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M47.037,82.306C11.477,61.776-11.261,124,35.047,127h89.403L47.037,82.306z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M80.707,47.988C60.176,12.428,9.286,55.094,47.978,80.7L125.4,125.4L80.707,47.988z"/>
      <path fill={fillColor2} stroke={strokeColor2} stroke-width="4" stroke-miterlimit="10" d="M127,35.06c0-41.061-65.406-29.555-44.699,11.966L127,124.451V35.06z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M151.157,37.975c10.626-39.661-55.433-45.5-46.273-0.012l23.139,86.356L151.157,37.975z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M193.046,61.608c29.032-29.033-25.354-67.146-40.072-23.145l-23.138,86.353L193.046,61.608z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M217.519,103.004c39.661-10.627,11.622-70.827-23.126-40.08l-63.216,63.216L217.519,103.004z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M218.024,151.157c39.662,10.626,45.502-55.433,0.011-46.272l-86.354,23.139L218.024,151.157z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M194.388,193.046c29.037,29.032,67.15-25.354,23.148-40.072l-86.354-23.137L194.388,193.046z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M152.996,217.519c10.627,39.661,70.825,11.622,40.079-23.126l-63.215-63.216L152.996,217.519z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M104.841,218.024c-10.625,39.662,55.435,45.502,46.274,0.011l-23.139-86.354L104.841,218.024z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M62.954,194.388c-29.034,29.037,25.349,67.15,40.07,23.149l23.137-86.354L62.954,194.388z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M38.479,152.996c-39.662,10.627-11.62,70.825,23.127,40.079l63.218-63.215L38.479,152.996z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M37.975,104.841c-39.662-10.625-45.5,55.436-0.012,46.275l86.356-23.14L37.975,104.841z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M61.609,62.955C32.575,33.92-5.538,88.304,38.462,103.023l86.354,23.138L61.609,62.955z"/>
      <path fill={fillColor} stroke={strokeColor} stroke-width="4" stroke-miterlimit="10" d="M103.003,38.479C92.376-1.184,32.176,26.86,62.923,61.605l63.217,63.219L103.003,38.479z"/>
      <circle fill={circleFillColor} stroke={circleStrokeColor} stroke-width="4" stroke-miterlimit="10" cx="128" cy="128" r="12.679"/>
    </svg>
  )
}

export default KikuLayered;